import React, { useEffect, useState } from 'react'
import { Link, useStaticQuery } from 'gatsby'
import SEO from '../components/seo'
import ReviewSlider from '../components/ReviewSlider'
import { Section, Grid, Image, Button, Gutter, Hero, Panel } from '@patomation/react-ui-components'
import Layout from '../components/Layout'
import PackageList from '../components/PackageList'

const Packages = () => {
  return (
    <Layout
      title='package'
      description=''
      hero_image='https://res.cloudinary.com/northernthaiescape/image/upload/e_improve,w_1000/v1568355102/20190116_095648_jrrwem.jpg'
      belowMain={
        <Section
          background='#324851'
          color='#ffffff'
          style={{
            textAlign: 'center',
            paddingTop: '3rem',
            paddingBottom: '3rem'
          }}>
          Still looking for the perfect tour? We can create a custom tour packages for any size group.
          <Gutter />
          <Link to='/contact'>
            <Button title='Contact Us' color='#ffffff' kind='outline' />
          </Link>
        </Section>
      }
      >
      <PackageList/>
    </Layout>
  )
}

export default Packages
